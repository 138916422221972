<style lang="scss" scoped>
.box {
  position: fixed;
  right: 16px;
  top: 144px;
  z-index: 2;

  .box-main {
    width: 0px;
    transition: 0.5s all;
    background: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding-bottom: 37px;
    overflow: hidden;
    .main {
      width: 375px;
      padding-left: 24px;
      padding-right: 24px;
      .title {
        justify-content: left;
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-bottom: 24px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: bold;
        margin-right: 16px;
        border-bottom: 2px solid rgba(1, 153, 153, 1);
      }
      .data-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .point{
          width: 3px;
          height: 3px;
          margin-right: 8px;
          background: rgba(1, 153, 153, 1);
          margin-left: 4px;
        }
        .data {
          line-height: 18px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
        .icon {
        }

        .icons{
          margin-left: 32px;
          width: 24px;
          img{
            width:24px;
          }
        }
      }
      .data-item:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.box-help {
  width: 22px;
  height: 44px;
  position: absolute;
  left: -22px;
  top: 12px;
  background-image: url("../../../../assets/features/hidden.svg");
  cursor: pointer;
  &#show {
    background-image: url("../../../../assets/features/show.svg");
  }
}

#showBox {
  width: 375px;
}

#left0 {
  right: 0;
}
.data-main{
  font-weight: bolder;
}
</style>
<template>
  <div class="feature">
    <div class="box" :id="showBox ? '' : 'left0'">
      <div
        class="box-help"
        @click="showBox = !showBox"
        :id="showBox ? '' : 'show'"
      ></div>
      <div class="box-main" :id="showBox ? 'showBox' : ''">
        <div class="main">
          <div class="title">特色描述</div>
          <div class="data-item" v-for="item of tableDatas">
            <!-- <div class="icons"> -->
            <!-- <img :src="item.img" class="icon" alt="" /> -->
            <!-- </div> -->
            <div class="point"></div>
            <div class="datas">
              <div class="data" v-for="(i,index) of item.datas" >{{ i }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBox: true,
    };
  },
  computed: {
    tableDatas() {
      let data;
      if (this.$store.state.meshType == "LFP 3000/HV") {
        data = [
          {
            datas: [' 自动上料，节省人工。'],
          },
          {
            datas:['尾料盲区小，节约材料，减少工序。']
          },
          {
            datas:['切割速度快，效率高。']
          },{
            datas:['自动定长。']
          },{
            datas:['接渣除尘专利，减少内壁附着。']
          }
        ]
        
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          {
            img: require("../../../../assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("../../../../assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("../../../../assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("../../../../assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("../../../../assets/features/1-5.svg"),
            datas: ["Scalable up to 160kWh 16 (Parallel)"],
          },
          {
            img: require("../../../../assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("../../../../assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("../../../../assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("../../../../assets/features/1-9.svg"),
            datas: ["Smart BMS system to", "optimize the performance"],
          },
        ];
      } else {
        data = [
          {
            img: require("../../../../assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("../../../../assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("../../../../assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("../../../../assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("../../../../assets/features/1-5.svg"),
            datas: ["Scalable up to 80kWh 16 (Parallel)"],
          },
          {
            img: require("../../../../assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("../../../../assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("../../../../assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("../../../../assets/features/1-9.svg"),
            datas: ["Smart BMS system to", "optimize the performance"],
          },
        ];
      }

      return data;
    },
  },
  created() {},
};
</script>
